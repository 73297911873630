import { DateTime } from "luxon";
Date.prototype.getFirstDayOfMonth = function() {
    return new Date(this.getFullYear(), this.getMonth(), 1);
}

Date.prototype.getLastDayOfMonth = function () {
    return new Date(this.getFullYear(), this.getMonth() + 1, 0)
}
 
//* Utilizamos "luxon" por que hay errores cuando se parsea directamente con el timezone
Date.prototype.getUTCJSON = function() {
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    let hours = this.getHours();
    let minutes = this.getMinutes();
    let result = DateTime.local(year, month, day, hours, minutes).toString();
    return result;
}